<template>
  <div class="content_body SalaryEntityCommissionScheme">
    <!-- 头部 -->
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small" @submit.native.prevent @keyup.enter.native="handleSearch">
            <el-form-item label="门店提成方案">
              <el-input v-model="searchData.Name" size="small" placeholder="输入门店提成方案搜索" clearable @clear="handleSearch">
              </el-input>
            </el-form-item>
            <el-form-item label="业绩取值方案">
              <el-select v-model="searchData.PerformanceSchemeID" placeholder="请选择" clearable size="small"
                @change="handleSearch">
                <el-option v-for="item in PerformanceSchemeValids" :key="item.ID" :label="item.Name" :value="item.ID">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="有效性">
              <el-select v-model="searchData.Active" placeholder="选择有效性" clearable size="small" @change="handleSearch">
                <el-option label="有效" :value="true"></el-option>
                <el-option label="无效" :value="false"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handleSearch" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button type="primary" size="small" @click="handleShow('add', 'dialogVisible')" v-prevent-click>新增
          </el-button>
        </el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <div class="martp_10">
      <el-table size="small" :data="tableData" v-loading="tableDataLoading">
        <el-table-column prop="Name" label="门店提成方案"></el-table-column>
        <el-table-column prop="PerformanceSchemeName" label="业绩取值方案"></el-table-column>
        <el-table-column :formatter="(row)=>row.Calculation==10 ? '取其一' : '叠加'" label="计算方式"></el-table-column>
        <el-table-column :formatter="(row)=>row.Active ? '有效' : '无效'" label="有效性">
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="handleShow('edit', 'dialogVisible',scope.row)"
              v-prevent-click>编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pad_15 text_right">
      <el-pagination background v-if="paginations.total > 0" @current-change="handleCurrentChange"
        :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout"
        :total="paginations.total"></el-pagination>
    </div>
    <!-- 新增编辑弹出层 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="1100px"
      @close="handleClose(dialogVisibleType, 'dialogVisible', 'formData')">
      <el-tabs v-model="activeName">
        <el-tab-pane label="方案设置" name="first">
          <el-scrollbar class="el-scrollbar_height">
            <el-form size="small" ref="formData" :model="formData" label-width="110px">
              <el-form-item label="门店提成方案" prop="Name"
                :rules="[{ required: true, message: '请输入门店提成方案', trigger: 'blur'}]">
                <el-input v-model="formData.Name" placeholder="请输入门店提成方案名称"></el-input>
              </el-form-item>
              <el-form-item label="业绩取值方案" prop="PerformanceSchemeID"
                :rules="[{ required: true, message: '请选择业绩取值方案', trigger: 'change'}]">
                <el-select v-model="formData.PerformanceSchemeID" placeholder="请选择">
                  <el-option v-for="item in PerformanceSchemeValids" :key="item.ID" :label="item.Name" :value="item.ID">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="dialogVisibleType=='edit'" prop="Active" label="有效性">
                <el-radio-group v-model="formData.Active">
                  <el-radio :label="true">有效</el-radio>
                  <el-radio :label="false">无效</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="计算方式" prop="Calculation">
                <span slot="label">
                  计算方式
                  <el-popover placement="top-start" width="850px" trigger="hover">
                    <p>计算方式说明：</p>
                    <p>比如公司规定：业绩小于1万提3%，业绩超过1万提5%，业绩超过3万提8%，业绩5万以上提12%，则员工的业绩为4万，则计算方式如下：</p>
                    <p>（1）取其一（按照达到最高提点计算）：则员工4万的业绩对应的提点为8%，则提成为40000x8%=3200元</p>
                    <p>（2）叠加（只有超出标准部门按高标准计算）：则员工业绩分开提成,分别按业绩0~1万、1~3万、3~5万区间计算：</p>
                    <p>1万以下：10000x3%=300；1~3万：（30000-10000）x5%=1000；3~5万：（40000-30000）x8%=800，则最终提成为：300+1000+800=2100元
                    </p>
                    <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                  </el-popover>
                </span>
                <el-radio v-model="formData.Calculation" label="10">取其一</el-radio>
                <el-radio v-model="formData.Calculation" label="20">叠加</el-radio>
              </el-form-item>
              <el-form-item label="提成方案" prop="Commission" :rules="[{ required: true, message: '请设置提成方案',}]">
                <el-button type="primary" size="small" @click="handleShow('addSetCriteria', 'innerVisible')">新增提成方案
                </el-button>
              </el-form-item>
            </el-form>
            <el-table size="small" :data="formData.Commission" style="width: calc(100% - 110px);margin-left:110px"
              class="martp_10 ">
              <el-table-column prop="BeginPerformance" label="开始业绩(大于)">
                <template slot-scope="scope">{{scope.row.BeginPerformance | NumFormat}}</template>
              </el-table-column>
              <el-table-column prop="EndPerformance" label="结束数额(元)">
                <template slot-scope="scope">{{scope.row.EndPerformance | NumFormat}}</template>
              </el-table-column>
              <el-table-column prop="Rate" label="比例提成">
                <template slot-scope="scope">{{scope.row.Rate}}%</template>
              </el-table-column>
              <el-table-column prop="Fixed" label="固定提成(元)">
                <template slot-scope="scope">￥{{scope.row.Fixed | NumFormat}}</template>
              </el-table-column>
              <el-table-column prop="address" label="操作" width="145px">
                <template slot-scope="scope">
                  <el-button type="primary" size="small" @click="handleShow('editSetCriteria', 'innerVisible',scope)">编辑
                  </el-button>
                  <el-button type="danger" size="small" @click="onSelConditionBtn(scope.$index)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane label="适用员工" name="second">
          <!-- 头部 -->
          <el-row>
            <el-col :span="20">
              <el-form :inline="true" size="small" @submit.native.prevent>
                <el-form-item label="员工" label-width="40px">
                  <el-input v-model="searchApplyEmployeeData.Name" size="small" placeholder="输入员工姓名/编号搜索" clearable>
                  </el-input>
                </el-form-item>
                <el-form-item label="职务" prop="JobID" label-width="60px">
                  <el-select v-model="searchApplyEmployeeData.JobID" placeholder="请选择" size="small" filterable
                    clearable>
                    <el-option v-for="item in jobTypeData" :label="item.JobName" :value="item.ID" :key="item.ID">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="在职状态" label-width="80px">
                  <el-select v-model="searchApplyEmployeeData.State" placeholder="请选择" size="small" filterable clearable
                    @change="handleEmployeeSearch">
                    <el-option label="在职" :value="true"></el-option>
                    <el-option label="离职" :value="false">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" size="small" v-prevent-click>搜索</el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="4" class="text_right">
              <el-button type="primary" size="small" @click="handleShow('addEmployee', 'EmployeeListBool')"
                v-prevent-click>添加员工</el-button>
            </el-col>
          </el-row>
          <el-table size="small" :data="ApplyEmployeesTableDataFilter()" style="width: 100%" max-height="450px"
            tooltip-effect="light">
            <el-table-column prop="Name" label="员工姓名"></el-table-column>
            <el-table-column prop="EmployeeID" label="员工编号"></el-table-column>
            <el-table-column prop="JobName" label="职务"></el-table-column>
            <el-table-column :formatter="(row)=>row.State ? '在职' : '离职'" label="在职状态"></el-table-column>
            <el-table-column show-overflow-tooltip label="所属单位">
              <template slot-scope="scope">
                <div v-if="!scope.row.ID">
                  <span v-for="(item, index) in scope.row.Entity" :key="index">{{ item.EntityName }}{{ item.IsPrimaryEntity ? "[主]" : ""
                    }}{{
                      scope.row.Entity.length == index + 1 ? "" : ","
                    }}</span>
                </div>
                <div v-else>
                  <span>{{scope.row.EntityName}}222</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="200">
              <template slot-scope="scope">
                <el-button size="small" type="primary"
                  @click="handleShow('employeeComputeEntity', 'employeeComputeEntityBool',scope)">配置业绩门店
                </el-button>
                <el-button size="small" type="danger" @click="handleDelApplyEmployee(scope.$index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>

        </el-tab-pane>
      </el-tabs>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible=false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleSave(dialogVisibleType)" :loading="saveLoading">保 存
        </el-button>
      </span>
    </el-dialog>
    <!-- 设置条件 -->
    <el-dialog width="30%" :title="setCriteriaTitle" :visible.sync="innerVisible"
      @close="handleClose(setCriteriaType, 'innerVisible', 'setCriteriaFormData')">
      <el-form size="small" ref="setCriteriaFormData" :model="setCriteriaFormData" label-width="110px">
        <el-form-item label="条件">
          <span slot="label"><span style="margin-right:4px;color:#f67979">*</span><span>业绩范围</span></span>
          <el-col :span="8">
            <el-form-item label-width="0" style="margin-bottom:0px !important;" prop="BeginPerformance" :rules="[
                { required: true, message: '请输入开始业绩'},
              ]">
              <el-input v-model="setCriteriaFormData.BeginPerformance" type="number"
                @blur="setCriteriaAmount('BeginPerformance',$event)" placeholder="请输入开始业绩">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2" class="dis_flex flex_x_center ">至</el-col>
          <el-col :span="8">
            <el-form-item label-width="0" style="margin-bottom:0px !important;" prop="EndPerformance" :rules="[
                { required: true, message: '请输入截止业绩'},
              ]">
              <el-input v-model="setCriteriaFormData.EndPerformance" type="number"
                @blur="setCriteriaAmount('EndPerformance',$event)" placeholder="请输入截止业绩">
              </el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="比例提成" prop="Rate" :rules="[
                { required: true, message: '请输入比例提成'},
              ]">
          <el-input v-model="setCriteriaFormData.Rate" v-enter-number3 type="number" @input="royaltyRateChange">
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item label="固定提成" prop="Fixed" :rules="[
                { required: true, message: '请输入固定提成'},
              ]">
          <el-input v-model="setCriteriaFormData.Fixed" type="number" @blur="setCriteriaAmount('Fixed',$event)">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="innerVisible=false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleSave(setCriteriaType)">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 员工列表 -->
    <el-dialog title="添加员工" :visible.sync="EmployeeListBool" width="1000px"
      @close="handleClose('addEmployee', 'EmployeeListBool')">
      <!-- 头部 -->
      <el-row>
        <el-col :span="24">
          <el-form :inline="true" size="small" @submit.native.prevent @keyup.enter.native="handleEmployeeSearch">
            <el-form-item label="搜索" label-width="40px">
              <el-input v-model="searchEmployeeData.Name" size="small" placeholder="输入员工名称/编号搜索" clearable
                @clear="handleEmployeeSearch">
              </el-input>
            </el-form-item>
            <el-form-item label="职务" prop="JobID" label-width="60px">
              <el-select v-model="searchEmployeeData.JobID" placeholder="请选择" size="small" filterable clearable
                @change="handleEmployeeSearch">
                <el-option v-for="item in jobTypeData" :label="item.JobName" :value="item.ID" :key="item.ID">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="在职状态" label-width="80px">
              <el-select v-model="searchEmployeeData.State" placeholder="请选择" size="small" filterable clearable
                @change="handleEmployeeSearch">
                <el-option label="在职" :value="true"></el-option>
                <el-option label="离职" :value="false">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handleEmployeeSearch" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-table size="small" :data="EmployeeTableData" ref="EmployeeTableData" style="width: 100%" max-height="450px"
        :row-key="(row)=>row.EmployeeID" @selection-change="handleSelectionChange" tooltip-effect="light">
        <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
        <el-table-column prop="Name" label="员工姓名"></el-table-column>
        <el-table-column prop="EmployeeID" label="员工编号"></el-table-column>
        <el-table-column prop="JobName" label="职务"></el-table-column>
        <el-table-column :formatter="(row)=>row.State ? '在职' : '离职'" label="在职状态"></el-table-column>
        <el-table-column show-overflow-tooltip
          :formatter="(row)=>{let str='' ;row.Entity && row.Entity.length &&  row.Entity.forEach(item=>str+=item.EntityName+';'); return str}"
          label="所属单位"></el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="pad_15 text_right">
        <el-pagination background v-if="EmployeePaginations.total > 0" @current-change="handleEmployeeCurrentChange"
          :current-page.sync="EmployeePaginations.page" :page-size="EmployeePaginations.page_size"
          :layout="EmployeePaginations.layout" :total="EmployeePaginations.total"></el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="EmployeeListBool=false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleSave('addEmployee')">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 员工适用门店 -->
    <el-dialog width="1000px" title="员工业绩门店" :visible.sync="employeeComputeEntityBool"
      @close="handleClose('employeeComputeEntity', 'employeeComputeEntityBool')">
      <el-scrollbar class="el-scrollbar_height">
        <el-tree :data="employeeComputeEntityList" show-checkbox node-key="ID" ref="tree" :expand-on-click-node="false"
          :check-on-click-node="true" :check-strictly="true" :default-checked-keys="defaultCheckedKeys"
          :props="defaultProps" :default-expanded-keys="defaultExpandedKeys">
          <span slot-scope="{ data }">
            <span class="font_14">{{ data.EntityName }}</span>
            <el-tag class="marlt_5" type="info" size="mini" v-if="data.IsStore">门店</el-tag>
            <el-tag class="marlt_5" type="info" size="mini" v-if="data.IsWarehouse">仓库</el-tag>
          </span>
        </el-tree>
      </el-scrollbar>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="employeeComputeEntityBool=false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleSave('employeeComputeEntity')">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/KHS/Salary/entityCommissionScheme";
import performanceAPI from "@/api/KHS/Salary/performanceScheme";
import APIJob from "@/api/KHS/Entity/jobtype";
import APIEntity from "@/api/KHS/Entity/entity";
export default {
  name: "SalaryEntityCommissionScheme",

  components: {},

  directives: {},
  computed: {
    ApplyEmployeesTableDataFilter() {
      return function () {
        if (
          !this.searchApplyEmployeeData.Name &&
          !this.searchApplyEmployeeData.JobID &&
          this.searchApplyEmployeeData.State === ""
        ) {
          return this.ApplyEmployeesTableData;
        }
        return this.ApplyEmployeesTableData.filter((item) => {
          return (
            (this.searchApplyEmployeeData.Name
              ? item.Name.toLowerCase().includes(this.searchApplyEmployeeData.Name.toLowerCase()) 
              ? true : item.EmployeeID.toLowerCase().includes(this.searchApplyEmployeeData.Name.toLowerCase())
              : true) &&
            (this.searchApplyEmployeeData.JobID
              ? item.JobID == this.searchApplyEmployeeData.JobID
              : true) &&
              (this.searchApplyEmployeeData.State === "" ? true : item.State==this.searchApplyEmployeeData.State)
          );
        });
      };
    },
  },

  data() {
    return {
      saveLoading: false,
      tableDataLoading: false,
      PerformanceSchemeValids: [], //业绩方案
      searchData: {
        Name: "",
        PerformanceSchemeID: "",
        Active: true,
      },
      tableData: [],
      paginations: {
        page: 1, // 当前位于哪页
        total: 20, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      // 新增编辑
      dialogTitle: "",
      dialogVisible: false,
      activeName: "first",
      dialogVisibleType: "",
      formData: {
        Name: "",
        PerformanceSchemeID: "",
        Commission: [],
        Calculation: "10",
      },
      // 新增编辑设置条件
      setCriteriaFormData: {
        BeginPerformance: "",
        EndPerformance: "",
        Rate: "",
        Fixed: "",
      },
      setCriteriaType: "", //弹层类型
      setCriteriaTitle: "", //设置条件弹出层标题
      innerVisible: false, //是否显示条件弹出层
      setCriteriaEditIndex: "",

      searchApplyEmployeeData: {
        Name: "",
        JobID: "",
        State: "",
      },
      ApplyEmployeesTableData: [], //适用员工列表
      selEmployeesTableData: [],

      searchEmployeeData: {
        Name: "",
        JobID: "",
        State: true,
      },
      EmployeeListBool: false, // 员工列表弹出层
      EmployeeTableData: [], //顾客列表数据
      EmployeePaginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      jobTypeData: [], //职务

      employeeComputeEntityBool: false, //员工计算门店弹层
      employeeComputeEntityList: [],
      defaultCheckedKeys: [],
      defaultExpandedKeys: [1],
      employeeID: "",
      defaultProps: {
        children: "Child",
        label: "EntityName",
      },
    };
  },

  mounted() {
    const that = this;
    //初始化-获取首页数据
    that.handleSearch();
    //初始化-获取业绩方案列表
    that.PerformanceSchemeValid();
    //初始化-获取员工职务列表
    that.getJobType();
    //初始化-获取门店列表
    that.entityData();
  },

  methods: {
    //初始化-获取业绩方案列表
    PerformanceSchemeValid() {
      const that = this;
      performanceAPI.PerformanceSchemeValid().then((res) => {
        if (res.StateCode == 200) {
          that.PerformanceSchemeValids = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //初始化-获取员工职务列表
    getJobType: function () {
      var that = this;
      var params = {
        JobTypeName: that.JobTypeName,
      };
      APIJob.getJobJobtypeAll(params).then((res) => {
        if (res.StateCode == 200) {
          that.jobTypeData = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //初始化-获取门店列表
    entityData: function () {
      var that = this;
      APIEntity.getEntityAll().then((res) => {
        if (res.StateCode == 200) {
          that.employeeComputeEntityList = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //详情-条件
    EntityCommissionSchemeCommission(ID) {
      const that = this;
      API.EntityCommissionSchemeCommission({ ID }).then((res) => {
        if (res.StateCode == 200) {
          that.formData.Commission = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //详情-员工
    EntityCommissionSchemeEmployee(ID) {
      const that = this;
      API.EntityCommissionSchemeEmployee({ ID }).then((res) => {
        if (res.StateCode == 200) {
          that.ApplyEmployeesTableData = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //详情-门店
    EntityCommissionSchemeEmployeeCalculateEntity(ID, index) {
      const that = this;
      API.EntityCommissionSchemeEmployeeCalculateEntity({ ID }).then((res) => {
        if (res.StateCode == 200) {
          that.ApplyEmployeesTableData[index].EntityID = res.Data;
          this.$refs.tree.setCheckedKeys(res.Data);
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },

    //搜索
    handleSearch() {
      this.paginations.page = 1;
      this.EntityCommissionSchemeAll();
    },
    //列表
    EntityCommissionSchemeAll() {
      const that = this;
      const params = {
        Name: that.searchData.Name,
        PerformanceSchemeID: that.searchData.PerformanceSchemeID,
        Active: that.searchData.Active,
        PageNum: that.paginations.page,
      };
      that.tableDataLoading = true;
      API.EntityCommissionSchemeAll(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = res.List;
            that.paginations.total = res.Total;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(() => {
          that.tableDataLoading = false;
        });
    },
    //分页
    handleCurrentChange() {
      this.EntityCommissionSchemeAll();
    },
    //门店提成弹层-新增/编辑-保存
    handleSaveBtn() {
      const that = this;
      that.$refs.formData.validate((valid) => {
        if (valid) {
          const params = Object.assign({}, that.formData);
          params.Handler = [];
          if (that.ApplyEmployeesTableData.length) {
            that.ApplyEmployeesTableData.forEach((item) => {
              params.Handler.push({
                EmployeeID: item.EmployeeID,
                Entity: item.EntityID ? item.EntityID : [],
              });
            });
          }
          let api;
          if (this.dialogVisibleType == "edit") {
            api = "EntityCommissionSchemeUpdate";
          } else {
            api = "EntityCommissionSchemeCreate";
          }
          that.saveLoading = true;
          API[api](params)
            .then((res) => {
              if (res.StateCode == 200) {
                that.$message.success({
                  message:
                    this.dialogVisibleType == "add" ? "添加成功" : "修改成功",
                  duration: 2000,
                });
                that.dialogVisible = false;
                that.handleSearch();
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(() => {
              that.saveLoading = false;
            });
        }
      });
    },
    //门店提成弹层-适用员工-删除
    handleDelApplyEmployee(index) {
      this.$confirm("此操作将删除该选项, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.ApplyEmployeesTableData.splice(index, 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //条件设置弹层-新增/编辑-金额格式
    setCriteriaAmount(type, e) {
      if (e.target.value.indexOf(".") != -1) {
        this.setCriteriaFormData[type] = Number(e.target.value).toFixed(2) - 0;
      } else {
        this.setCriteriaFormData[type] = Number(e.target.value);
      }
    },
    //条件设置弹层-新增/编辑-比例提成
    royaltyRateChange(e) {
      if (e > 100) {
        this.setCriteriaFormData.Rate = 100;
      }
    },
    //条件设置弹层-新增/编辑-保存
    setCriteriaSaveBtn() {
      const that = this;
      this.$refs.setCriteriaFormData.validate((valid) => {
        if (valid) {
          if (
            that.setCriteriaFormData.BeginPerformance -
              that.setCriteriaFormData.EndPerformance >
            0
          ) {
            that.$message.error({
              message: "结束数额不能小于开始数额",
              duration: 2000,
            });
            return;
          }
          // 条件不能重复
          var num = that.formData.Commission.findIndex((item, index) => {
            if (
              that.setCriteriaType == "editSetCriteria" &&
              index == that.setCriteriaEditIndex
            )
              return false;
            const num1 = Number(item.BeginPerformance);
            const num2 = Number(item.EndPerformance);
            const num3 = Number(that.setCriteriaFormData.BeginPerformance);
            const num4 = Number(that.setCriteriaFormData.EndPerformance);
            if (num1 <= num3 && num2 >= num4) return true;
            if (num1 >= num3 && num2 <= num4) return true;
            if (num1 >= num3 && num4 >= num1 && num2 >= num4) return true;
            if (num1 <= num3 && num3 <= num2 && num2 <= num4) return true;
          });
          if (num != -1) {
            that.$message.error({
              message: "条件设置存在重复数额",
              duration: 2000,
            });
            return;
          }
          switch (that.setCriteriaType) {
            case "addSetCriteria":
              that.formData.Commission.push({ ...that.setCriteriaFormData });
              break;
            case "editSetCriteria":
              var index = that.setCriteriaEditIndex;
              that.formData.Commission.splice(index, 1, {
                ...that.setCriteriaFormData,
              });
              break;
          }
          that.innerVisible = false;
        }
      });
    },
    //条件设置弹层-新增/编辑-删除
    onSelConditionBtn(index) {
      this.$confirm("此操作将删除该选项, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.formData.Commission.splice(index, 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //员工列表弹层-搜索
    handleEmployeeSearch() {
      const that = this;
      that.EmployeePaginations.page = 1;
      that.EntityCommissionSchemeAllEmployee();
    },
    //员工列表弹层-列表
    EntityCommissionSchemeAllEmployee() {
      const that = this;
      const params = {
        PageNum: that.EmployeePaginations.page,
        Name: that.searchEmployeeData.Name,
        JobID: that.searchEmployeeData.JobID,
        State: that.searchEmployeeData.State,
      };
      API.EntityCommissionSchemeAllEmployee(params).then((res) => {
        if (res.StateCode == 200) {
          that.EmployeeTableData = res.List;
          that.EmployeePaginations.total = res.Total;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //员工列表弹层-分页
    handleEmployeeCurrentChange() {
      this.EntityCommissionSchemeAllEmployee();
    },
    //员工列表弹层-多选
    handleSelectionChange(e) {
      this.selEmployeesTableData = e;
    },
    //员工列表弹层-保存
    handleAddEmployee() {
      const that = this;
      that.ApplyEmployeesTableData = this.selEmployeesTableData;
      that.EmployeeListBool = false;
    },
    //员工计算门店弹层-保存
    handleEmployeeComputeEntitySaveBtn() {
      const that = this;
      const Index = that.ApplyEmployeesTableData.findIndex(
        (item) => item.EmployeeID == that.employeeID
      );
      that.$set(
        that.ApplyEmployeesTableData[Index],
        "EntityID",
        that.$refs.tree.getCheckedKeys()
      );

      // that.ApplyEmployeesTableData[that.employeeID].EntityID =
      //   that.$refs.tree.getCheckedKeys();
      that.employeeComputeEntityBool = false;
    },
    // 显示弹层
    async handleShow(type, bool, row) {
      const that = this;
      that[bool] = true;
      switch (type) {
        // 新增门店提成弹层
        case "add":
          that.dialogVisibleType = type;
          that.dialogTitle = "新增门店业绩提成方案";
          break;
        // 编辑门店提成弹层
        case "edit":
          that.dialogVisibleType = type;
          that.dialogTitle = "编辑门店业绩提成方案";
          that.EntityCommissionSchemeCommission(row.ID);
          that.EntityCommissionSchemeEmployee(row.ID);
          await that.$nextTick();
          that.formData = {
            Name: row.Name,
            PerformanceSchemeID: row.PerformanceSchemeID,
            Commission: [],
            Calculation: row.Calculation,
            ID: row.ID,
          };
          that.$set(that.formData, "Active", row.Active);
          break;
        // 添加条件设置弹层
        case "addSetCriteria":
          that.setCriteriaTitle = "新增提成方案";
          that.setCriteriaType = type;
          break;
        // 编辑条件设置弹层
        case "editSetCriteria":
          that.setCriteriaTitle = "编辑提成方案";
          that.setCriteriaType = type;
          var scope = row;
          that.setCriteriaEditIndex = scope.$index;
          row = scope.row;
          await that.$nextTick();
          that.setCriteriaFormData = {
            BeginPerformance: row.BeginPerformance,
            EndPerformance: row.EndPerformance,
            Rate: row.Rate,
            Fixed: row.Fixed,
          };
          break;
        // 员工列表弹层
        case "addEmployee":
          that.EntityCommissionSchemeAllEmployee();
          await that.$nextTick();
          if (that.ApplyEmployeesTableData.length) {
            that.ApplyEmployeesTableData.forEach((row) => {
              that.$refs.EmployeeTableData.toggleRowSelection(row);
            });
          }
          break;
        // 员工计算的门店弹层
        case "employeeComputeEntity":
          var index = row.$index;
          row = row.row;
          this.employeeID = row.EmployeeID;
          if (this.dialogVisibleType == "edit") {
            if (row.EntityID) {
              this.$refs.tree.setCheckedKeys(row.EntityID);
            } else if (row.ID) {
              this.EntityCommissionSchemeEmployeeCalculateEntity(row.ID, index);
            }
          } else {
            if (row.EntityID && row.EntityID.length) {
              this.$refs.tree.setCheckedKeys(row.EntityID);
            }
          }
          break;
      }
    },
    // 保存弹层
    handleSave(type) {
      const that = this;
      switch (type) {
        case "add":
        case "edit":
          that.handleSaveBtn();
          break;
        case "addSetCriteria":
        case "editSetCriteria":
          that.setCriteriaSaveBtn();
          break;
        case "addEmployee":
          that.handleAddEmployee();
          break;
        case "employeeComputeEntity":
          that.handleEmployeeComputeEntitySaveBtn();
          break;
      }
    },
    // 关闭弹层
    handleClose(type, bool, ref) {
      const that = this;
      let isRef = false; //控制是否执行Ref清空表单
      that[bool] = false; //关闭弹层

      switch (type) {
        case "add":
        case "edit":
          that.ApplyEmployeesTableData = [];
          that.activeName = "first";
          that.searchApplyEmployeeData = {
            Name: "",
            JobID: "",
          };
          break;
        case "addSetCriteria":
        case "editSetCriteria":
          break;
        case "addEmployee":
          isRef = true;
          that.$refs.EmployeeTableData.clearSelection();
          that.EmployeePaginations.page = 1;
          that.searchEmployeeData = {
            Name: "",
            JobID: "",
          };
          break;
        case "employeeComputeEntity":
          isRef = true;
          this.$refs.tree.setCheckedKeys([]);
          break;
      }
      if (isRef) return;
      that.$refs[ref].resetFields();
    },
  },
};
</script>

<style lang="scss" >
.SalaryEntityCommissionScheme {
  .el-scrollbar_height {
    height: 55vh;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
  .el-input__inner {
    padding: 0 0 0 15px;
  }
}
</style>